/*jshint esnext: true */
/* jshint latedef:nofunc */
/*global jQuery, dis */

/* DIS/PLAY Script
 Author's name: Michael Milo
 Modified by:
 Client name: ...
 Date of creation: ...
 */


(function ($, dis) {
	"use strict";

	 /*
	 * @param objectConfiguration - The configuration for the object
	 * @param {jQuery|HTMLElement|string|*} objectConfiguration.container - The DOM-container for this instantiation
	 * @constructor
	 * @extends dis.BaseModule
	 */
	dis.documentArchiveModule = function (objectConfiguration) {

		dis.BaseModule.call(this);

		var defaults = {
				nextPage: 		1,
				totalItems: 	'',
				totalPages: 	'',
				pageSize:     	'',
				documentDT:  	'',
				dateFormat: 	'YYYY-MM-DD',
				timer: 			null,
				delay: 			1000
			},

			configuration = $.extend(defaults, objectConfiguration),
			dom = {},
			eventHandlers,
			selfScope = this,

			eventHandlers = {
				getDocuments: function (e) {
					selfScope.getDocuments();

					e.preventDefault();
				},

				rowExpandClick: function(e) {
					var tr = $(e.target).parents('tr'),
						row = configuration.documentDT.row(tr);

						if ( row.child.isShown() ) {
							row.child.hide();
							tr.removeClass('shown');
						}
						else {
							row.child( rowChild( tr.data('text') ) ).show();
							tr.addClass('shown');
						}
				},

				filtersWasModified: function(e) {

					clearTimeout(configuration.timer);

					if (e && e.type === "input") {
						var key = e.which || e.keyCode;

						if (key !== 16 && // shift
							key !== 17 && // ctrl
							key !== 18 && // alt
							key !== 37 && // left
							key !== 39 && // right
							key !== 9) { // tab
							configuration.timer = setTimeout(function() {
								setCreationDateValues();
								configuration.documentDT.clear().draw();
								configuration.nextPage = 1;
								selfScope.getDocuments();
							}, configuration.delay );
						}
					} else {
						setCreationDateValues();
						configuration.documentDT.clear().draw();
						configuration.nextPage = 1;
						selfScope.getDocuments();
					}
				},

				dateRangeInserted: function(e) {
					var triggerInputName,
						startDate,
						endDate,
						modalSubmitBtn = $(e.currentTarget);

					triggerInputName = e.currentTarget.getAttribute('data-trigger-input');

					startDate = modalSubmitBtn.parents('.modal-content').find('.StartDate').val();
					endDate = modalSubmitBtn.parents('.modal-content').find('.EndDate').val();

					dom.createDateMin.val( moment(startDate, 'DD-MM-YY').format(configuration.dateFormat) );
					dom.createDateMax.val( moment(endDate, 'DD-MM-YY').format(configuration.dateFormat) );

					var selectBox = dom.form.find('[name=creationPeriod]');
					if(selectBox.find('.customdate').length === 1) {
						selectBox.find('.customdate').text(startDate + " - " + endDate);
					}
					else {
						selectBox.find("option:last").before('<option class="customdate" value="custom">'+ startDate + " - " + endDate +'</option>');
					}

					//dom.form.find("[name=creationPeriod] option:last").text(startDate + " - " + endDate);
					selectBox.val('custom').select2();

					configuration.documentDT.clear().draw();
					configuration.nextPage = 1;
					selfScope.getDocuments();

					$('#dateRangeModalTemplate').modal('hide');
				}
			};


		/*
			PRIVATE functions
		*/

		function initDataTable() {
			configuration.documentDT = dom.documentTable.DataTable({
				bLengthChange: 	false,
				searching: 		false,
				bInfo:   		false,
				bPaginate: 		false,
				responsive: 	true,
				documentDT: 	'',

				"language": {
					"emptyTable": "Ingen data tilgængelig"
				},

				"order": 		[[ 1, "desc" ]],

				"fnCreatedRow" 	: function( nRow, aData ) {
					if(aData[6].length) {
						$(nRow).attr('data-text', aData[6]);
					}
					else {
						$(nRow).find('i.row-expand').hide();
					}

					dom.submitButton.children('totalItems').text( configuration.totalItems - dom.documentTable.find('tbody tr').length );
			    },

			    "columnDefs": [
		            {
		                "targets": [ 6 ],
		                "visible": false
		            }
		        ]
			});

			selfScope.getDocuments();
		}

		function rowChild (data) {
		    return '<div class"foobar"><strong>Note:</strong> '+data+'</div>';
		}

		function appendRows(items) {
			items.map(function(item) {

				configuration.documentDT.row.add( [
					'<i class="icon icon-plus row-expand"></i>',
					moment(item.createDate).format('DD.MM.YYYY'),
		            item.id,
		            item.bookerName,
		            item.userName,
		            item.title,
		            item.text ? item.text : '',
		            item.fileUrl ? '<a href="' + item.fileUrl + '" target="_blank" rel="noopener"><i class="icon icon-download"></i></a>' : ''
		        ] ).draw( false );
			});
		}

		function setInitialStates(values) {
			if(configuration.nextPage === 1) {
				configuration.totalItems 	= values.totalItems;
				configuration.totalPages 	= values.totalPages;
				configuration.pageSize 		= values.pageSize;

				dom.submitButton.children('pageSize').text(configuration.pageSize);
			}
		}

		function getPeriodStartIntervalDate() {
			var startDate;

			switch(parseInt(dom.creationPeriodSelect.val())) {
				case 1:
					startDate = moment().subtract('3', 'days').format(configuration.dateFormat);
					break;
				case 2:
					startDate = moment().subtract('7', 'days').format(configuration.dateFormat);
					break;
				case 3:
					startDate = moment().subtract('30', 'days').format(configuration.dateFormat);
					break;
				default:
					startDate = '';
			}

			return startDate;
		}

		function today() {
			var endDate = moment().format(configuration.dateFormat);

			return endDate;
		}

		function setCreationDateValues() {
			var currentSelectionVal = dom.creationPeriodSelect.val();

			if(currentSelectionVal !== '4' && currentSelectionVal !== 'custom') {
				switch(currentSelectionVal) {
					case '1':
						dom.createDateMin.val(getPeriodStartIntervalDate(1));
						dom.createDateMax.val('');
						break;
					case '2':
						dom.createDateMin.val(getPeriodStartIntervalDate(2));
						dom.createDateMax.val(today());
						break;
					case '3':
						dom.createDateMin.val(getPeriodStartIntervalDate(3));
						dom.createDateMax.val(today());
						break;
					default:
						dom.createDateMin.val('');
						dom.createDateMax.val('');
				}

				resetCustomDateOption();
			}
		}

		function resetCustomDateOption() {
			dom.form.find("[name=creationPeriod] .customdate").remove();
		}




		/*
			PUBLIC functions
		*/

		this.getDocuments = function () {
			 var serializedData = dom.form.serializeArray();

			 serializedData.push({ name: "page", value: configuration.nextPage });

    		//serializedData.push({ name: "CreateDateMin", value: getPeriodStartDate() });
    		//serializedData.push({ name: "CreateDateMax", value: getPeriodEndDate() });

			$.ajax({
				method: "POST",
				url: "/dokumentskab",
				dataType: 'json',
				data: serializedData
			})

			.done(function( msg ) {

				setInitialStates(msg);
				appendRows(msg.items);

				if(configuration.nextPage >= configuration.totalPages) {
					dom.submitButton.hide();
				}
				else {
					dom.submitButton.show();
				}

				configuration.nextPage = configuration.nextPage + 1;
			})

			.error(function() {
				alert('API\'et returnerede en ukendt fejl. Data kunne ikke hentes.');
			});

			return selfScope;
		};


		function init() {
			// Get and store the DOM-reference to the container we've been given.
			dom.container = $(configuration.container);

			if (!dom.container.length) {
				// If we haven't got a container at this point, throw a hissy fit!
				throw "Given container could not be found, or no container given. That's bad!";
			}

			dom.form 					= dom.container.find('form');
			dom.documentTable 			= dom.container.find('table');
			dom.creationPeriodSelect	= dom.form.find('[name=creationPeriod]');
			dom.submitButton			= dom.container.find('.btn-load-more');
			dom.filterInputs 			= dom.form.find(':input');
			dom.createDateMin 			= dom.form.find('[name=CreateDateMin]');
			dom.createDateMax 			= dom.form.find('[name=CreateDateMax]');


			initDataTable();


			/**
			* Handle click on either of the submit buttons
			*/
			dom.form.on('submit', eventHandlers.getDocuments);

			dom.documentTable.on('click', 'tbody .row-expand', eventHandlers.rowExpandClick);

			dom.filterInputs.on('keyup change', eventHandlers.filtersWasModified);

			$('body').on('click', '.btn-date-interval', eventHandlers.dateRangeInserted);
		}


		selfScope.onReady(init);

	};

	dis.documentArchiveModule.prototype = new dis.BaseModule();
	dis.documentArchiveModule.constructor = dis.documentArchiveModule;


// This is where you define the global parameters you pass into your closure. You'll want to pass in any
// big stuff you'll need inside your script. Minor stuff can always be accessed through window.someVariable.
}(jQuery, dis));

