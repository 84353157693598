/*jshint esnext: true */
/*global jQuery, dis */

/* DIS/PLAY Script
 Author's name: ...
 Modified by:
 Client name: ...
 Date of creation: ...
 */

(function ($, dis) {
	"use strict";


	function init() {
		$("#skystatus-module").each(function() {
			var skyStatus = new dis.skyStatusModule({container: $(this)});
			skyStatus = skyStatus;
		});

		$("#booking-module").each(function() {
			var booking = new dis.bookingModule({container: $(this)});
			booking = booking; //To avoid jshint warn about unused var.
		});

		$("#filters-module").each(function() {
			dis.filters = new dis.filtersModule({container: $(this)});
		});

		$("#filter-header-module").each(function() {
			dis.filterHeader = new dis.filterHeaderModule({container: $(this)});
		});

		$("#booking-overview-module").each(function() {
			dis.bookingOverview = new dis.bookingOverviewModule({container: $(this)});
		});

		$("#document-archive-module").each(function() {
			var archiveModule = new dis.documentArchiveModule({container: $(this)});
			archiveModule = archiveModule; //To avoid jshint warn about unused var.
		});

		$("#create-document-module").each(function() {
			var createDocumentModule = new dis.createDocumentModule({container: $(this)});
			createDocumentModule = createDocumentModule; //To avoid jshint warn about unused var.
		});

		$("#history-module").each(function() {
			var historyModule = new dis.historyModule({container: $(this)});
			historyModule = historyModule; //To avoid jshint warn about unused var.
		});

		$("#import-bookings").each(function () {
			new dis.importBookingsModule({container: $(this)});
		});


		function matchStart (term, text) {
			if (text.toUpperCase().indexOf(term.toUpperCase()) === 0) {
				return true;
			}

			return false;
		}

		$.fn.select2.amd.require(['select2/compat/matcher'], function (oldMatcher) {
			$('.select2').select2({
				width: '100%',
				minimumResultsForSearch: 0,
				matcher: oldMatcher(matchStart)
			});

			//Fix a bug in select2, causing the dom to forget current focused inputfield, if the select2 has a search box.
			$('.select2').on('select2:open', function (e) {
				$(e.currentTarget).focus();
			}).on("select2:close", function (e) {
				$(e.currentTarget).focus();
			}).find(".select2-selection").keydown(function (e) {
				var key = e.which || e.keyCode;

				if (key !== 16 && // shift
					key !== 17 && // ctrl
					key !== 18 && // alt
					key !== 9) { // tab
					$(this).closest(".select2-prefix").find("select").select2("open");
				}
			});
		});

		$.fn.datepicker.dates.da = {
		    days: ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag"],
		    daysShort: ["Søn", "Man", "Tir", "Ons", "Tor", "Fre", "Lør"],
		    daysMin: ["Sø", "Ma", "Ti", "On", "To", "Fr", "Lø"],
		    months: ["Januar", "Februar", "Marts", "April", "Maj", "Juni", "Juli", "August", "September", "Oktober", "November", "December"],
		    monthsShort: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"],
		    today: "I dag",
		    clear: "Nulstil",
		    format: "dd-mm-yy",
		    titleFormat: "MM yyyy",
		    weekStart: 1
		};


		$('.datepicker').datepicker({
		    autoclose: true,
		    language: 'da',
			assumeNearbyYear: true
		}).on("blur", function () {
			var current = $(this),
				currentValue = current.val().replace(/\./g, "-").replace(/[^0-9-]/g, "").replace(/-$/, ""),
				dashCount = (currentValue.match(/-/g)||[]).length,
				time = new Date(),
				month = time.getMonth() + 1,
				year = time.getFullYear().toString().substr(2, 2);

			// Only continue if the field has a value
			if (currentValue) {
				if (!dashCount) {
					// Add month and year
					current.val(currentValue + "-" + month + "-" + year);
				} else if (dashCount === 1) {
					// Add year
					current.val(currentValue + "-" + year);
				} else {
					// Swap value with parsed string ("-" instead of "." and only numbers and dashes)
					current.val(currentValue);
				}
				current.trigger("change");
			}
		}).keydown(function () {
			var input = $(this);

			// Continue if datepicker is visible
			if (input.data('datepicker').picker.is(":visible")) {
				// Hide datepicker when the date is being typed
				//input.datepicker("hide");
			}
		}).on("changeDate", function() {
			$(this).blur();
		});

		$('.datepicker:not(.datepicker-show-on-focus)').off("focus").on("focus", function () {
			var input =  $(this).parent().find('input.datepicker');

			if (input.data('datepicker').picker.is(":visible")) {
				// Hide datepicker
				//input.datepicker("hide");
			}
		});

		moment.locale('da');

		var defaultDate;
		$(".datepicker").each(function() {
			defaultDate = $(this).val() ? $(this).val() : moment().format('DD.MM.YYYY');
		    $(this).datepicker('setDate', defaultDate);
		});

		$('.datepicker-container .input-group-addon').on('click', function() {
			var input =  $(this).parent().find('input.datepicker');

			if (input.data('datepicker').picker.is(":visible")) {
				// Hide datepicker
				input.datepicker("hide");
			} else {
				// Show datepicker
				input.datepicker("show");
			}
		});

		$('select.hasDateRangeOption').on('change', function() {
			if( parseInt($(this).val()) === 4) {
				$('#dateRangeModalTemplate').find('.btn-date-interval').attr('data-trigger-input', $(this).prop('name'));
				$('#dateRangeModalTemplate').modal();
			}
		});


	    $(':file').on('change', function() {
	    	$(this).parents('.input-group').find('input[type="text"]').val($(this).val());
	    });

	    $('body').on('change', '.modal .StartDate', function() {
	    	var startDateElm 	= $('.modal .StartDate'),
				endDateElm 		= $('.modal .EndDate'),
				startDate 		= moment(startDateElm.val(), 'DD.MM.YY').format('YYYY-MM-DD'),
				endDate 		= moment(endDateElm.val(), 'DD.MM.YY').format('YYYY-MM-DD'),
				diff 			= moment(endDate, 'YYYY-MM-DD').diff(moment(startDate, 'YYYY-MM-DD'), 'days');

			if(diff < 0) {
				endDate = moment(startDate, 'YYYY-MM-DD').add(1, 'days').format('DD-MM-YY');
				endDateElm.val(endDate);
			}
	    });
	}


	// Initialize the main script(s) once the DOM is ready. Not only does this mean that all your DOM-references will
	// work, but more importantly: Gulp is compiling all your scripts into one big file, but the closures are run as soon
	// as they're available. Meaning: if your "main" closure is run BEFORE your "module"-closure, it might try to instantiate
	// your modules before the browser actually knows them. Using jQuery's ready-function alleviates this problem.
	dis.BaseModule.prototype.onReady(init);


}(jQuery, dis));

