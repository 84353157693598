/*jshint esnext: true */
/* jshint latedef:nofunc */
/*global jQuery, dis */

/* DIS/PLAY Script
 Author's name: Michael Milo
 Modified by:
 Client name: ...
 Date of creation: ...
 */


(function ($, dis) {
	"use strict";

	 /*
	 * @param objectConfiguration - The configuration for the object
	 * @param {jQuery|HTMLElement|string|*} objectConfiguration.container - The DOM-container for this instantiation
	 * @constructor
	 * @extends dis.BaseModule
	 */
	dis.skyStatusModule = function (objectConfiguration) {

		dis.BaseModule.call(this);

		var defaults = {
				dateFormat: 'YYYY-MM-DD',
				weeksSubtracted: 0,
				yearlyLimit: 0,
				yearlyCount: 0,
				startDate: moment().format('YYYY-MM-DD'),
				currentWeek: moment().isoWeek(),
				startDateInputHasBeenFocused: 0,
				currentDevice: ''
			},

			configuration = $.extend(defaults, objectConfiguration),
			dom = {},
			selfScope = this,

			eventHandlers = {
				toggleSkyStatusVisibilityEvent: function(e = false) {

					if(dom.container.hasClass('open')) {
						dom.container.removeClass('open');
						Cookies.remove('showSkyStatus');
					}
					else {
						dom.container.addClass('open');
						Cookies.set('showSkyStatus', 1);
					}

					if(e !== false) {
						e.preventDefault();
					}
				},

				loadSkyCirclesEvent: function(e) {
					configuration.weeksSubtracted = configuration.weeksSubtracted - 1;
					selfScope.getSkyStatusData(true);

					e.preventDefault();
				},

				startDateChangeEvent: function() {
					if(! configuration.startDateInputHasBeenFocused) {
						return false;
					}

					configuration.startDate =  moment(dom.startDateInput.val(), 'DD.MM.YY').format(configuration.dateFormat);
					configuration.currentWeek = moment(configuration.startDate, configuration.dateFormat).isoWeek();
					configuration.weeksSubtracted = 0;

					removeDayCircles();
					selfScope.getSkyStatusData();
				},

				startDateHasBeenFocusedEvent: function() {
					configuration.startDateInputHasBeenFocused = 1;
				},

				calendarIconClickEvent: function() {
					dom.startDateInput.focus();
				},

				dayControllerMobileClickEvent: function(e) {
					if($(e.currentTarget).hasClass('prev')) {
						configuration.startDate = moment(configuration.startDate).subtract(1, 'days').format('YYYY-MM-DD');
					}
					else {
						configuration.startDate = moment(configuration.startDate).add(1, 'days').format('YYYY-MM-DD');
					}

					dom.startDateInput.datepicker('setDate', moment(configuration.startDate).format('DD.MM.YYYY'));

					selfScope.getSkyStatusData();
				}
			};


		/*
			PRIVATE functions
		*/

		function drawDayCircles() {

			dom.dayCircleContainer.find('.day-circle:not(.svg-container)').each(function() {

				var _this = $(this);

				_this.circliful({
				 	animation: 1,
		            animationStep: 100,
		            foregroundBorderWidth: 3,
		            backgroundBorderWidth: 3,
		            foregroundColor: '#3587bb',
		            backgroundColor: '#d1d1d1',
		            targetColor: '#313131',
		            percent: _this.data('pct'),
		            replacePercentageByText: _this.data('val'),
		        	targetTextSize: 22,
		        	fontColor: '#3587bb',
		            text: _this.data('date-str'),
		            textStyle: 'font-size: 22px; font-weight: normal;',
		            textColor: '#6c6c6c',
		            targetPercent: (_this.data('target-pct') === 0 ? 0.0001 : _this.data('target-pct') / 57 * 69), // Must not be zero for some reason
		            noPercentageSign: true
			    }, function() {
					var context = $(this.context),
						textFieldPercent = context.find('text:eq(2)'),
						textFieldTargetPercent = context.find('text:eq(0)');

			    	if(_this.data('is-today') === true) {
						context.find('text:eq(1)').attr('fill', '#000000').css('font-weight', 'bold').text('I dag');
			    	}

					if(_this.data('pct') === 0) {
						textFieldPercent.attr('fill', '#ff0000').css("font-weight", "900");
					}

					if(_this.data('target-pct') === 0) {
						textFieldTargetPercent.attr('fill', '#ff0000').css("font-weight", "900");
					}

			    	textFieldPercent.attr("y", 137);
			    	textFieldTargetPercent.text(_this.data('target-val')).attr("y", 79);

					context.addClass('visible');
			    });
			});

			if(configuration.currentDevice === 'desktop') {
				$('[data-toggle="tooltip"]').tooltip({html: true});
			}
		}

		function drawTotalSailingDaysCircle() {
			if(configuration.currentDevice === 'mobile') {
				return false;
			}

			dom.totalSailingDaysCircle.each(function() {

				var _this = $(this);
				var pct = Math.ceil((configuration.yearlyCount / configuration.yearlyLimit * 100));

				_this.circliful({
				 	animation: 1,
		            animationStep: 200,
		            foregroundBorderWidth: 3,
		            backgroundBorderWidth: 3,
		            foregroundColor: '#26c871',
		            backgroundColor: '#d1d1d1',
		            percent: pct,
		            replacePercentageByText: configuration.yearlyCount,
		        	fontColor: '#26c871',
		            text:'',
		            noPercentageSign: true
			    }, function() {
			        var _this = $(this.context);

			        _this.find('.timer').attr('y', '90');
			        _this.find('text:first').attr('y', '117').attr('width', '80');
			    });
			});
		}

		function appendDayCircles(data) {
			/* jshint unused:vars */

			var dayAbbre,
				dayStr,
				firstStationVal,
				anyStationVal,
				firstStationPct,
				anyStationPct,
				currentCircleDate,
				domStr = '',
				isCurrentStartDate,
				firstStationLimit,
				dailyLimit;

			$.each(data.skyCircles, function(index, day) {
				currentCircleDate = moment(day.date).format(configuration.dateFormat);

				if(moment(currentCircleDate).isSame(new Date(), "day")) {
					isCurrentStartDate = 'true';
				}
				else {
					isCurrentStartDate = '';
				}

				dayAbbre = moment(day.date).format('dddd').substring(0, 3).toLowerCase();
				dayStr = dayAbbre + ' ' + moment(day.date).format('[d.] DD');

				firstStationVal = day.firstStationAvailable;
				anyStationVal = day.anyStationAvailable;

				firstStationLimit = day.firstStationLimit;
			    dailyLimit = day.anyStationLimit;

				firstStationPct = firstStationLimit <= 0 || firstStationVal <= 0 ? 0 : Math.round(firstStationVal / firstStationLimit * 100);
				anyStationPct = dailyLimit <= 0 || anyStationVal <= 0 ? 0 : Math.round(anyStationVal / dailyLimit * 100);

				//firstStationPct = 100 - firstStationPct;
				//anyStationPct = 100 - anyStationPct;

				domStr += '<div class="day-circle" data-is-today="'+ isCurrentStartDate +'"  data-date-str="'+ dayStr +'" data-val="' + firstStationVal +'" data-target-val="' + anyStationVal +'"  data-pct="'+ firstStationPct +'" data-target-pct="'+ anyStationPct +'" data-toggle="tooltip" title=" '+ moment(currentCircleDate).format('DD.MM.YYYY') +'<br /><strong>Tørring:</strong> '+ firstStationVal +' ledige (af '+ firstStationLimit +')<br/><strong>Strækning 1:</strong> '+ anyStationVal +' ledige (af '+ dailyLimit +')"></div>';
			});

			if(configuration.currentDevice === 'mobile') {
				dom.dayCircleContainer.empty();
			}

			dom.dayCircleContainer.append(domStr);

			drawDayCircles();
		}

		function removeDayCircles() {
			dom.dayCircleContainer.empty();
		}

		function setDeviceType() {
			if($(window).width() < 768) {
				configuration.currentDevice = 'mobile';
				reorderHeaderForMobileView();
				wrapSlideControlsHolder();
			}
			else {
				configuration.currentDevice = 'desktop';
			}
		}

		function reorderHeaderForMobileView() {
			dom.dayCircleContainer.insertBefore(".header-content");
		}

		function wrapSlideControlsHolder() {
			dom.dayCircleContainer.wrap('<div id="day-controller-mobile"></div>');
			dom.dayCircleContainer.parent().prepend('<div class="control prev"></div><div class="control next"></div>');
		}

		/*
			PUBLIC functions
		*/

		this.getSkyStatusData = function (getYearFromDataset) {
			var navigatedWeekNumber = moment().week(configuration.currentWeek).subtract(configuration.weeksSubtracted, 'weeks').isoWeek(),
				navigatedYear = parseInt(moment(configuration.startDate).format("YYYY")),
				fromYear = (moment(configuration.startDate).format("MM") === "01" && navigatedWeekNumber > 50 ? navigatedYear - 1 : navigatedYear),
				dateFrom,
				dateTo;

			if (getYearFromDataset) {
				fromYear = parseInt(dom.loadCirclesBtn.data("year"));
			}

			if(configuration.currentDevice === 'desktop') {
				dateFrom = moment().week(navigatedWeekNumber).year(fromYear).startOf('isoWeek').format( configuration.dateFormat );
				dateTo = moment(dateFrom).add(6, 'days').format( configuration.dateFormat );
			}

			if(configuration.currentDevice === 'mobile') {
				dateFrom = moment(configuration.startDate).subtract(1, 'days').format('YYYY-MM-DD');
				dateTo = moment(configuration.startDate).add(1, 'days').format('YYYY-MM-DD');
			}

			dom.loadCirclesBtn.data("year", moment(dateTo).format("YYYY"));

			$.ajax({
				method: "GET",
				url: '/umbraco/api/cloud/GetAvailable',
				dataType: 'json',
				data: {
					From: 	dateFrom,
					To:  	dateTo
				}
			})

			.done(function(data) {
				configuration.yearlyCount = data.yearlyAvailable;
				configuration.yearlyLimit = data.yearlyLimit;

				dom.totalSailingDaysCircle.attr('data-text', 'Af '+ configuration.yearlyLimit + ' årlige sejldage');
				dom.totalSailingDaysCircle.attr('data-pct-replacement-text', configuration.yearlyCount);
				dom.totalSailingDaysCircle.attr('data-pct', Math.ceil((configuration.yearlyCount / configuration.yearlyLimit * 100)));

				dom.totalSailingDaysMobile.find('.days').text(configuration.yearlyCount);
				dom.totalSailingDaysMobile.find('.days-limit').text(configuration.yearlyLimit);

				drawTotalSailingDaysCircle();

				appendDayCircles(data);
			})

			.error(function(msg) {
				console.log(msg);
			});

			return selfScope;
		};

		function init() {
			// Get and store the DOM-reference to the container we've been given.
			dom.container = $(configuration.container);

			if (!dom.container.length) {
				// If we haven't got a container at this point, throw a hissy fit!
				throw "Given container could not be found, or no container given. That's bad!";
			}

			dom.expandToggle 			= dom.container.find('.expand-toggle');
			dom.dayCircleContainer 		= dom.container.find('#day-circle-container');
			dom.totalSailingDaysCircle 	= dom.container.find('#total-sailing-days-circle');
			dom.totalSailingDaysMobile 	= dom.container.find('#total-sailing-days-mobile');
			dom.loadCirclesBtn   		= dom.container.find('.circle-load-button');
			dom.startDateInput 			= dom.container.find('input[name="skystatus_starting_date"]');
			dom.calendarIcon 			= dom.container.find('.icon-calendar');


			dom.expandToggle.on('click', eventHandlers.toggleSkyStatusVisibilityEvent);
			dom.loadCirclesBtn.on('click', eventHandlers.loadSkyCirclesEvent);
			dom.startDateInput.on('change', eventHandlers.startDateChangeEvent);
			dom.startDateInput.on('focus', eventHandlers.startDateHasBeenFocusedEvent);
			dom.calendarIcon.on('click', eventHandlers.calendarIconClickEvent);

			dom.container.on('click', '#day-controller-mobile .control', eventHandlers.dayControllerMobileClickEvent);


			if(Cookies.get('showSkyStatus') === '1') {
				eventHandlers.toggleSkyStatusVisibilityEvent();
			}

			setDeviceType();

			selfScope.getSkyStatusData();
		}


		selfScope.onReady(init);

	};

	dis.skyStatusModule.prototype = new dis.BaseModule();
	dis.skyStatusModule.constructor = dis.skyStatusModule;


// This is where you define the global parameters you pass into your closure. You'll want to pass in any
// big stuff you'll need inside your script. Minor stuff can always be accessed through window.someVariable.
}(jQuery, dis));

