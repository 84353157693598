(function ($, dis) {
	"use strict";

	 /*
	 * @param objectConfiguration - The configuration for the object
	 * @param {jQuery|HTMLElement|string|*} objectConfiguration.container - The DOM-container for this instantiation
	 * @constructor
	 * @extends dis.BaseModule
	 */
	dis.importBookingsModule = function (objectConfiguration) {
		dis.BaseModule.call(this);
		var selfScope = this;
		var container = objectConfiguration.container;

		function init() {
			
			var $form = container.find("form");
			var $loader = container.find(".loader");
			var $submit = $form.find("button[type='submit']");
			var $errors = container.find(".errors");

			$form.on("submit", function () {
				$submit.prop("disabled", true);
				$loader.show();
				$errors.hide();
			});

		}

		selfScope.onReady(init);
	};

	dis.importBookingsModule.prototype = new dis.BaseModule();
	dis.importBookingsModule.constructor = dis.importBookingsModule;

// This is where you define the global parameters you pass into your closure. You'll want to pass in any
// big stuff you'll need inside your script. Minor stuff can always be accessed through window.someVariable.
}(jQuery, dis));

