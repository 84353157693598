 /*jshint esnext: true */
 /* jshint latedef:nofunc */
/*global jQuery, dis */

/* DIS/PLAY Script
 Author's name: Michael Milo
 Modified by:
 Client name: ...
 Date of creation: ...
 */


(function ($, dis) {
	"use strict";

	 /*
	 * @param objectConfiguration - The configuration for the object
	 * @param {jQuery|HTMLElement|string|*} objectConfiguration.container - The DOM-container for this instantiation
	 * @constructor
	 * @extends dis.BaseModule
	 */
	dis.filtersModule = function (objectConfiguration) {

		dis.BaseModule.call(this);

		var defaults = {
				timer: 			null,
				delay: 			1000,
				dateFormat: 	'YYYY-MM-DD',
			},

			configuration = $.extend(defaults, objectConfiguration),
			dom = {},
			eventHandlers,
			selfScope = this,

			eventHandlers = {
				filtersWasModified: function (e) {
					clearTimeout(configuration.timer);

					if (e && e.type === "keyup") {
						var key = e.which || e.keyCode;

						if (key !== 16 && // shift
							key !== 17 && // ctrl
							key !== 18 && // alt
							key !== 37 && // left
							key !== 39 && // right
							key !== 9) { // tab
							configuration.timer = setTimeout(function() {
								storeFiltersCookie();
								dis.bookingOverview.getBookings(true);
							}, configuration.delay );
						}
					} else {
						storeFiltersCookie();
						dis.bookingOverview.getBookings(true);
					}

					e.preventDefault();
				},

				dateRangeInserted: function(e) {
					var triggerInputName,
						startDate,
						endDate,
						modalSubmitBtn = $(e.currentTarget);

					triggerInputName = e.currentTarget.getAttribute('data-trigger-input');

					startDate = modalSubmitBtn.parents('.modal-content').find('.StartDate').val();
					endDate = modalSubmitBtn.parents('.modal-content').find('.EndDate').val();

					setDateParameters(triggerInputName, startDate, endDate);

					$('#dateRangeModalTemplate').modal('hide');
				}
			};


		/*
			PRIVATE functions
		*/

		function setDateParameters(type, startDate, endDate)  {
			var startDateName,
				endDateName;

			switch(type) {
				case 'creationPeriod':
					startDateName 	= 'StartCreatedTime';
					endDateName 	= 'EndCreatedTime';

					break;
				case 'startPeriod':
					startDateName 	= 'StartPeriodMin';
					endDateName 	= 'StartPeriodMax';

					break;
				case 'endPeriod':
					startDateName 	= 'EndPeriodMin';
					endDateName 	= 'EndPeriodMax';

					break;
			}

			dom.form.find("[name='"+ startDateName +"']").val( moment(startDate, 'DD-MM-YY').format(configuration.dateFormat) );
			dom.form.find("[name='"+ endDateName +"']").val(  moment(endDate, 'DD-MM-YY').format(configuration.dateFormat) );

			storeFiltersCookie();
			updateCustomDateOptionText(type, startDate, endDate);

			dis.bookingOverview.bookingsListReset().getBookings();
		}

		function updateCustomDateOptionText(type, startDate, endDate) {
			//dom.form.find("[name="+type+"] option:last").text(startDate + " - " + endDate);
			var selectBox = dom.form.find("[name="+type+"]");


			if(selectBox.find('.customdate').length === 1) {
				selectBox.find('.customdate').text(startDate + " - " + endDate);
			}
			else {
				selectBox.find("option:last").before('<option class="customdate" value="custom">'+ startDate + " - " + endDate +'</option>');
			}

			dom.form.find("[name="+type+"]").val('custom');
			dom.form.find("[name="+type+"]").select2();
		}


		function storeFiltersCookie() {
			setDateRangeFieldValues();

			var inputFields 			= dom.form.find(':input'),
				serializedFilters 		= inputFields.serialize(),
			 	serializedFiltersArr 	= inputFields.serializeArray(),
			 	serializedFiltersJSON 	= JSON.stringify(serializedFiltersArr);

			Cookies.set('filters_json', serializedFiltersJSON);
			Cookies.set('filters_str', serializedFilters);

			//Update excel form with current filters
			dis.filterHeader.updateExcelDownloadParameters();
		}

		function storedFiltersExists() {
			var filters = Cookies.get('filters_json');

			if(filters === undefined) {
				return false;
			}
			else {
				return true;
			}
		}

		function setDateRangeFieldValues() {
			var startDateName,
				endDateName,
				today = moment().format(configuration.dateFormat);

			$(".hasDateRangeOption").each(function() {

				var currentName = $(this).attr('name'),
					currentValue = $(this).val();

				switch(currentName) {
					case 'creationPeriod':
						startDateName 	= 'StartCreatedTime';
						endDateName 	= 'EndCreatedTime';

						break;
					case 'startPeriod':
						startDateName 	= 'StartPeriodMin';
						endDateName 	= 'StartPeriodMax';

						break;
					case 'endPeriod':
						startDateName 	= 'EndPeriodMin';
						endDateName 	= 'EndPeriodMax';

						break;
				}

				switch(currentValue) {
					case '1':
						dom.form.find("[name="+startDateName+"]").val( moment().subtract('3', 'days').format(configuration.dateFormat) );
						dom.form.find("[name="+endDateName+"]").val( today );
						resetCustomDateOption(currentName);
						break;

					case '2':
						dom.form.find("[name="+startDateName+"]").val( moment().subtract('7', 'days').format(configuration.dateFormat) );
						dom.form.find("[name="+endDateName+"]").val( today );
						resetCustomDateOption(currentName);
						break;

					case '3':
						dom.form.find("[name="+startDateName+"]").val( moment().subtract('30', 'days').format(configuration.dateFormat) );
						dom.form.find("[name="+endDateName+"]").val(today );
						resetCustomDateOption(currentName);
						break;

					case '4':
						//Handled via date range modal
						break;

					case 'custom':
						//Handled via date range modal
						break;

					default:
						dom.form.find("[name="+startDateName+"]").val('');
						dom.form.find("[name="+endDateName+"]").val('');
						resetCustomDateOption(currentName);
						break;
				}

			});

		}

		function resetCustomDateOption(selectName) {
			var selectBox = dom.form.find("[name="+selectName+"]");

			if(selectBox.val() !== 'custom' && selectBox.val() !== '4') {
				selectBox.find(".customdate").remove().end().select2();
			}
		}

		function setInputStatesFromCookie() {
			if( ! storedFiltersExists() ) {
				return false;
			}

			var filters = Cookies.get('filters_json');


			$.each(JSON.parse(filters), function(index, filter) {
				index = index;

				$('[name='+filter.name+']').val(filter.value);

				if(filter.name === 'creationPeriod' &&  (filter.value === '4' || filter.value === 'custom')) {
					var startDate = moment(getFilterValueFromName(filters, 'StartCreatedTime')).format('DD-MM-YY'),
						endDate = moment(getFilterValueFromName(filters, 'EndCreatedTime')).format('DD-MM-YY');

					updateCustomDateOptionText(filter.name, startDate, endDate);
				}

				if(filter.name === 'startPeriod' &&  (filter.value === '4' || filter.value === 'custom')) {
					var startDate = moment(getFilterValueFromName(filters, 'StartPeriodMin')).format('DD-MM-YY'),
						endDate = moment(getFilterValueFromName(filters, 'StartPeriodMax')).format('DD-MM-YY');

					updateCustomDateOptionText(filter.name, startDate, endDate);
				}

				if(filter.name === 'endPeriod' &&  (filter.value === '4' || filter.value === 'custom')) {
					var startDate = moment(getFilterValueFromName(filters, 'EndPeriodMin')).format('DD-MM-YY'),
						endDate = moment(getFilterValueFromName(filters, 'EndPeriodMax')).format('DD-MM-YY');

					updateCustomDateOptionText(filter.name, startDate, endDate);
				}
			});
		}

		function getFilterValueFromName(filters, filterName) {
			/* jshint unused:vars */
			var filterValue;

			$.each(JSON.parse(filters), function(index, filter) {
				if(filter.name === filterName) {
					filterValue = filter.value;
					return false;
				}
			});

			return filterValue;
		}




		/*
			PUBLIC functions
		*/

		this.initFilters = function () {
			setInputStatesFromCookie();

			return selfScope;
		};

		this.toggleFilterVisibility = function() {
			dom.container.toggleClass('hidden');

			var filterButton = $('#filter-header-module .filter-btn');

			if(dom.container.hasClass('hidden')) {
				filterButton.removeClass('open').find('.text').text('Vis filtre');
			}
			else {
				filterButton.addClass('open').find('.text').text('Skjul filtre');
			}
		};


		function init() {
			// Get and store the DOM-reference to the container we've been given.
			dom.container = $(configuration.container);

			if (!dom.container.length) {
				// If we haven't got a container at this point, throw a hissy fit!
				throw "Given container could not be found, or no container given. That's bad!";
			}

			dom.form 			= dom.container.find('form');
			dom.filterInputs 	= dom.container.find(':input');
			dom.creationPeriod 	= dom.form.find("[name=creationPeriod]");
			dom.startPeriod 	= dom.form.find("[name=startPeriod]");
			dom.endPeriod 		= dom.form.find("[name=endPeriod]");

			selfScope.initFilters();


			/**
			* Handle filter changes
			*/
			dom.filterInputs.on('keyup change', eventHandlers.filtersWasModified);

			$('body').on('click', '.btn-date-interval', eventHandlers.dateRangeInserted);

			$('#dateRangeModalTemplate').on('show.bs.modal', function () {
				//$('#dateRangeModalTemplate').find('input.StartDate, input.EndDate').val( moment().format('DD.MM.YY') );
				/*
				$("#dateRangeModalTemplate .datepicker").each(function() {
				    $(this).datepicker('setDate', moment().format('DD.MM.YYYY'))
				    .on('hide', function(e) {
				       console.log(e);
				    });

				});*/
			});

		}


		selfScope.onReady(init);

	};

	dis.filtersModule.prototype = new dis.BaseModule();
	dis.filtersModule.constructor = dis.filtersModule;


// This is where you define the global parameters you pass into your closure. You'll want to pass in any
// big stuff you'll need inside your script. Minor stuff can always be accessed through window.someVariable.
}(jQuery, dis));

