/*jshint esnext: true */
/* jshint latedef:nofunc */
/*global jQuery, dis */

/* DIS/PLAY Script
 Author's name: Michael Milo
 Modified by:
 Client name: ...
 Date of creation: ...
 */


(function ($, dis) {
	"use strict";

	 /*
	 * @param objectConfiguration - The configuration for the object
	 * @param {jQuery|HTMLElement|string|*} objectConfiguration.container - The DOM-container for this instantiation
	 * @constructor
	 * @extends dis.BaseModule
	 */
	dis.createDocumentModule = function (objectConfiguration) {

		dis.BaseModule.call(this);

		var defaults = {

			},

			configuration = $.extend(defaults, objectConfiguration),
			dom = {},
			eventHandlers,
			selfScope = this,

			eventHandlers = {
				formSubmitEvent: function (e) {
					var hasError,
						_parent;

					dom.inputRequired.each(function() {
						_parent = $(this).parents('.form-group');

						if(! $(this).val()) {
							hasError = 1;
							_parent.addClass('has-error');
						} else {
							_parent.removeClass('has-error');
						}
					});
					
					if(hasError) {
						e.preventDefault();
					}
				},
			};  


		/*
			PRIVATE functions
		*/
	
 
		/*
			PUBLIC functions
		*/



		function init() {
			// Get and store the DOM-reference to the container we've been given.
			dom.container = $(configuration.container);
			
			if (!dom.container.length) {
				// If we haven't got a container at this point, throw a hissy fit!
				throw "Given container could not be found, or no container given. That's bad!";
			}

			dom.form 					= dom.container.find('form');
			dom.inputRequired			= dom.form.find('.required');


			/**
			* Handle click on either of the submit buttons
			*/
			dom.form.on('submit', eventHandlers.formSubmitEvent);
		}


		selfScope.onReady(init);

	};

	dis.createDocumentModule.prototype = new dis.BaseModule();
	dis.createDocumentModule.constructor = dis.createDocumentModule;


// This is where you define the global parameters you pass into your closure. You'll want to pass in any
// big stuff you'll need inside your script. Minor stuff can always be accessed through window.someVariable.
}(jQuery, dis));

