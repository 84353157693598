/*jshint esnext: true */
/* jshint latedef:nofunc */
/*global jQuery, dis */

/* DIS/PLAY Script
 Author's name: Michael Milo
 Modified by:
 Client name: ...
 Date of creation: ...
 */


(function ($, dis) {
	"use strict";

	/*
	* @param objectConfiguration - The configuration for the object
	* @param {jQuery|HTMLElement|string|*} objectConfiguration.container - The DOM-container for this instantiation
	* @constructor
	* @extends dis.BaseModule
	*/
	dis.bookingModule = function (objectConfiguration) {

		dis.BaseModule.call(this);

		var getParameterByName = function (key) {  
			return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));  
		};

		var defaults = {
			reservationId: '',
			initialReservationId: '',
			dateFormat: 'YYYY-MM-DD',
			txtTryReservationSuccess: 'Ledig plads',
			txtTryReservationOccupied: 'Rute optaget, skift dato eller antal kanoer',
			txtTryReservationError: 'Ugyldig indtastning',
			txtTryReservationLoading: 'Tjekker om der er ledige ruter...',
			tryReserveIsValidated: false,
			tryReserveIsNotNeeded: false,
			todaysDate: moment().format('YYYY-MM-DD'),
			editingState: 1, // 1 = editing is allowed, 2 = editing of actual data only is allowed
		},

			configuration = $.extend(defaults, objectConfiguration),
			dom = {},
			eventHandlers,
			selfScope = this,
			timerInterval,


			eventHandlers = {
				FormSubmitEvent: function (e) {
					var returnToForm = $(e.currentTarget).hasClass('submit-and-new') ? true : false;
					selfScope.book(returnToForm, e);

					e.preventDefault();
				},

				FormEnterKeyEvent: function (e) {
					var keyCode = e.keyCode || e.which;

					if (keyCode === 13) {
						e.preventDefault();
						return false;
					}
				},

				amountWasChanged: function () {
					selfScope.checkAvailability();
				},

				startDateChangedEvent: function () {
					checkIfEndDateIsValid();
					selfScope.checkAvailability();
				},

				endDateChangedEvent: function () {
					checkIfStartDateIsValid();
					selfScope.checkAvailability();
				},

				stationSelectWasChanged: function () {
					selfScope.checkAvailability();
				}
			};


		/*
			PRIVATE functions
		*/

		/**
		 * When changing startDate, set endDate to the day after startDate, if current endDate is in the past.
		 */
		function checkIfEndDateIsValid() {
			var startDate = moment(dom.startDate.val(), 'DD-MM-YY').format(configuration.dateFormat),
				endDate = moment(dom.endDate.val(), 'DD-MM-YY').format(configuration.dateFormat),
				diff = moment(endDate, configuration.dateFormat).diff(moment(startDate, configuration.dateFormat), 'days');

			if (diff < 0) {
				endDate = moment(startDate, configuration.dateFormat).add(0, 'days').format('DD-MM-YY');
				dom.endDate.val(endDate).datepicker('update');
			}
		}

		/**
		 * When changing endDate, set startDate to the same day as endDate, if current startDate is in the future.
		 */
		function checkIfStartDateIsValid() {
			var startDate = moment(dom.startDate.val(), 'DD-MM-YY').format(configuration.dateFormat),
				endDate = moment(dom.endDate.val(), 'DD-MM-YY').format(configuration.dateFormat),
				diff = moment(endDate, configuration.dateFormat).diff(moment(startDate, configuration.dateFormat), 'days');

			if (diff < 0) {
				startDate = moment(endDate, configuration.dateFormat).add(0, 'days').format('DD-MM-YY');
				dom.startDate.val(startDate).datepicker('update');
			}
		}

		/**
		* Validate if all mandatory fields for making a tryReserve request are set.
		*/
		function mandatoryFieldsAreSet() {
			if (
				dom.startDate.val() &&
				dom.endDate.val() &&
				dom.startStation.val() &&
				dom.endStation.val() &&
				dom.amount.val()
			) {
				return true;
			}
		}

		/**
		* Cancel any ongoing timers. Happens if a user has first fired a valid a valid request and
		* then afterwards changes the parameters to something invalid
		*/
		function cancelTimer() {
			dom.timer.addClass('hidden');
			clearInterval(timerInterval);
		}

		/**
		* Enable submitbuttons
		*/
		function enableSubmit() {
			dom.submitButtons.prop('disabled', false);
		}


		/**
		* Disable submitbuttons
		*/
		function disableSubmit() {
			dom.submitButtons.prop('disabled', true);
		}


		function resetFormState() {
			configuration.tryReserveIsValidated = false;

			cancelTimer();
			disableSubmit();
		}


		/**
		* When checkAvailability() returns success, start the 5 minute timer
		*/
		function startTimer() {
			enableSubmit();

			var secondsEllapsed = 0,
				fiveMin = 60 * 5,
				timeleft,
				minLeft,
				secLeft;

			dom.timer.removeClass('hidden');

			dom.timer.find('.minutes').text("5");
			dom.timer.find('.seconds').text("0");

			clearInterval(timerInterval);

			timerInterval = setInterval(function () {
				secondsEllapsed = secondsEllapsed + 1;

				timeleft = fiveMin - secondsEllapsed;
				minLeft = parseInt(timeleft / 60);
				secLeft = parseInt(timeleft % 60);

				dom.timer.find('.minutes').text(minLeft);
				dom.timer.find('.seconds').text(secLeft);
				dom.countdownModalTimer.text(secLeft);

				if (timeleft === 30) {
					dom.countdownModal.modal();
				} else if (timeleft === 0) {
					clearInterval(timerInterval);
					dom.countdownModal.modal('hide');
					resetFormState();
				}

			}, 1000);
		}

		/**
		* Hide the availability box
		*/
		function availabilityHide() {
			dom.availabilityStatus.text('').addClass('hidden');
			dom.bookingError.text('');
		}

		/**
		* When checkAvailability() returns success
		* @param int reservationId
		*/
		function availabilitySuccess(reservationId) {
			//Update the reservation ID state
			configuration.reservationId = reservationId;
			configuration.tryReserveIsValidated = true;

			dom.availabilityStatus.text(configuration.txtTryReservationSuccess);
			dom.bookingError.text('');

			startTimer();
		}


		/**
		* When checkAvailability() returns no available spots
		*/
		function availabilityOccupied() {
			dom.availabilityStatus.addClass('error').text(configuration.txtTryReservationOccupied);
			dom.bookingError.text('');
			resetFormState();

			// Reset reservation ID
			configuration.reservationId = configuration.initialReservationId;
		}




		/**
		* When checkAvailability() returns a invalid request
		*/
		function availabilityError(errorMsg) {
			dom.availabilityStatus.addClass('error').text(configuration.txtTryReservationError);
			dom.bookingError.text(errorMsg);
			resetFormState();

			// Reset reservation ID
			configuration.reservationId = configuration.initialReservationId;
		}


		/**
		* Set loading state before checkAvailability() starts the AJAX request
		*/
		function availabilitySetLoadingState() {
			dom.availabilityStatus.removeClass('hidden error').text(configuration.txtTryReservationLoading);
			resetFormState();
		}


		/**
		* Format startDate to YYYY-MM-DD syntax
		*/
		function getFormattedStartDate() {
			var d = dom.startDate.val();
			d = moment(d, 'DD.MM.YYYY').format(configuration.dateFormat);

			return d;
		}


		/**
		* Format endDate to YYYY-MM-DD syntax
		*/
		function getFormattedEndDate() {
			var d = dom.endDate.val();
			d = moment(d, 'DD.MM.YYYY').format(configuration.dateFormat);
			return d;
		}

		function shouldTryReservation() {
			return false === (dom.startStation.find('option:selected').data('isnorth') === 'True' && dom.endStation.find('option:selected').data('isnorth') === 'True');
		}

		function isEditing() {
			if (dom.reservationId.length) {
				return true;
			}
			else {
				return false;
			}
		}

		function setEditingState() {
			configuration.initialReservationId = dom.reservationId.val();

			//If sailing end date is in the FUTURE of today OR today
			if (moment(getFormattedEndDate()).diff(configuration.todaysDate, 'days') >= 0) {
				configuration.editingState = 1;
				configuration.reservationId = dom.reservationId.val();
			}

			//If sailing end date is in the PAST of today
			if (moment(getFormattedEndDate()).diff(configuration.todaysDate, 'days') < 0) {
				configuration.editingState = 2;
			}
		}


		/*
			PUBLIC functions
		*/

		/**
		* Perform the tryReserve API request
		* @return self
		*/
		var checkAvailabilityAjax = null;
		this.checkAvailability = function () {
			if (!mandatoryFieldsAreSet()) {
				return false;
			}

			//Only editing actual data, no need to ask the tryReserve API
			if (configuration.editingState === 2) {
				enableSubmit();
				availabilityHide();
				configuration.tryReserveIsNotNeeded = 1;

				return false;
			}

			if (!shouldTryReservation()) {
				enableSubmit();
				availabilityHide();

				return false;
			}

			availabilitySetLoadingState();


			if (checkAvailabilityAjax) {
				checkAvailabilityAjax.abort();
			}
			checkAvailabilityAjax = $.ajax({
				method: "GET",
				url: "/umbraco/api/cloud/tryreserve/",
				dataType: 'json',
				data: {
					start: getFormattedStartDate(),
					end: getFormattedEndDate(),
					amount: dom.amount.val(),
					from: dom.startStation.val(),
					to: dom.endStation.val(),
					reservationId: configuration.reservationId,
					pageId: configuration.pageId
				}
			})

				.done(function (msg) {
					checkAvailabilityAjax = null;

					if (msg.success) {
						availabilitySuccess(msg.reservation.id);
					}
					else {
						availabilityOccupied();
					}
				})

				.error(function (xhr) {
					var errorMsg = '';

					checkAvailabilityAjax = null;

					if (xhr.statusText === "abort") {
						return;
					}

					if (xhr.responseJSON !== undefined) {
						errorMsg = xhr.responseJSON.message;
					}
					else if (xhr.responseText) {
						errorMsg = xhr.responseText;
					}
					else {
						errorMsg = xhr.statusText;
					}

					availabilityError(errorMsg);
				});

			return selfScope;
		};


		/**
		* Perform the /sejlads (booking) API request
		* @param returnToForm Defines if the "Book and create new" was clicked.
		* @return self
		*/
		this.book = function (returnToForm, event) {
			if (shouldTryReservation() && !configuration.tryReserveIsNotNeeded) {
				if (!configuration.tryReserveIsValidated) {
					return false;
				}

				if (!dom.name.val()) {
					dom.name.parents('.form-group').addClass('has-error').find(':input').focus();
					return false;
				}
				else {
					dom.name.parents('.form-group').removeClass('has-error');
				}
			}

			$.ajax({
				method: "POST",
				url: dom.form.prop('action'),
				dataType: 'html',
				data: {
					ufprt: dom.ufprt.val(),
					bookerId: dom.bookerId.val(),
					startDate: getFormattedStartDate(),
					endDate: getFormattedEndDate(),
					amount: dom.amount.val(),
					startStation: dom.startStation.val(),
					endStation: dom.endStation.val(),
					customerName: dom.name.val(),
					phone: dom.phone.val(),
					reservationId: configuration.reservationId
				}
			})

				.done(function () {
					var gotoUrl = "/bookingSaved?url=";

					if (event && event.target) {
						var target = $(event.target);

						if (target.data("url")) {
							gotoUrl += target.data("url");
						} else {
							gotoUrl += "/";
						}
					} else if (returnToForm) {
						gotoUrl += "/sejlads/";
					}
					else {
						gotoUrl += "/";
					}

					window.location.href = gotoUrl;
				})

				.error(function (msg) {
					console.log(msg);
				});

			return selfScope;
		};


		function init() {
			// Get and store the DOM-reference to the container we've been given.
			dom.container = $(configuration.container);

			if (!dom.container.length) {
				// If we haven't got a container at this point, throw a hissy fit!
				throw "Given container could not be found, or no container given. That's bad!";
			}

			dom.form = dom.container.find('form');
			dom.ufprt = dom.container.find("input[name='ufprt']");
			dom.bookerId = dom.container.find('select[name=BookerId]');
			dom.startDate = dom.container.find('.StartDate');
			dom.endDate = dom.container.find('.EndDate');
			dom.startStation = dom.container.find('.startStation');
			dom.endStation = dom.container.find('.endStation');
			dom.type = dom.container.find('.type');
			dom.name = dom.container.find('input[name=CustomerName]');
			dom.phone = dom.container.find('input[name=CustomerPhone]');
			dom.availabilityStatus = dom.container.find('.register-availability-status');
			dom.bookingError = dom.container.find('.error-messages');
			dom.timer = dom.container.find('.availability-timer');
			dom.amount = dom.container.find('input[name=Amount]');
			dom.submitButtons = dom.container.find("[type=submit]");
			dom.reservationId = dom.container.find("[name=reservationId]");
			dom.pageId = dom.container.find("[name=pageId]");
			dom.inputFields = dom.form.find(':input');
			dom.reset = dom.form.find('button[type=reset]');

			dom.countdownModal = $("#reservationCountdownModal");
			dom.countdownModalTimer = dom.countdownModal.find(".modal-countdown");
			dom.countdownModalCancel = dom.countdownModal.find(".btn-default");
			dom.countdownModalAccept = dom.countdownModal.find(".btn-primary");

			configuration.pageId = dom.pageId.val();

			/**
			 * Handle click on cancel-button
			 */
			dom.reset.click(function () {
				$.get("/umbraco/api/cloud/cancelReservation?pageId=" + configuration.pageId + "&id=" + configuration.reservationId, function () {
					window.location.href = "/";
				});
			});

			/**
			 * Handle click on cancel ("annuller") in countdown modal
			 */
			dom.countdownModalCancel.click(function () {
				dom.countdownModal.modal('hide');
				resetFormState();
			});

			/**
			 * Handle click on accept in countdown modal
			 */
			dom.countdownModalAccept.click(function () {
				dom.countdownModal.modal('hide');
			});


			/**
			* Handle change events on the start and enddate selectboxes
			*/
			dom.startDate.on('blur', eventHandlers.startDateChangedEvent);
			dom.endDate.on('blur', eventHandlers.endDateChangedEvent);

			/**
			* Handle change events on the station selectboxes
			*/
			dom.startStation.on('change', eventHandlers.stationSelectWasChanged);
			dom.endStation.on('change', eventHandlers.stationSelectWasChanged);

			/**
			* Handle change events on the amount text input
			*/
			dom.amount.on('change', eventHandlers.amountWasChanged);

			/**
			* Handle click on either of the submit buttons
			*/
			//dom.submitButtons.on('click', eventHandlers.FormSubmitEvent);

			/**
			* Handle enter keystroke event on the booking form, to prevent enter submitting the form
			*/
			dom.form.on('keyup keypress', eventHandlers.FormEnterKeyEvent);

			/**
			* Handle click on either of the submit buttons
			*/
			//dom.form.on('submit', eventHandlers.FormSubmitEvent);

			dom.submitButtons.on('click', eventHandlers.FormSubmitEvent);
			dom.submitButtons.on('keydown', function (e) {
				var key = e.which || e.keyCode;

				if (key === 13) {
					eventHandlers.FormSubmitEvent(e);
				}
			});

			if (isEditing()) {
				setEditingState();

				/**
				 * Changing the phonenumber in editing state enables the submit button
				 */
				dom.phone.change(selfScope.checkAvailability);
			}
		}

		selfScope.onReady(init);
		/**
		 * On a second visit show datepicker
		 */
		var isARebook = getParameterByName('rebook');
		if (isARebook) {
			$(window).load(function() {
				// dom.startDate.datepicker("show");
				dom.startDate.focus();
				dom.startDate.select();
			})
		}

	};

	dis.bookingModule.prototype = new dis.BaseModule();
	dis.bookingModule.constructor = dis.bookingModule;


	// This is where you define the global parameters you pass into your closure. You'll want to pass in any
	// big stuff you'll need inside your script. Minor stuff can always be accessed through window.someVariable.
}(jQuery, dis));

