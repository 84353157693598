 /*jshint esnext: true */
/*global jQuery, dis */

/* DIS/PLAY Script
 Author's name: Michael Milo
 Modified by:
 Client name: ...
 Date of creation: ...
 */


(function ($, dis) {
	"use strict";

	 /*
	 * @param objectConfiguration - The configuration for the object
	 * @param {jQuery|HTMLElement|string|*} objectConfiguration.container - The DOM-container for this instantiation
	 * @constructor
	 * @extends dis.BaseModule
	 */
	dis.filterHeaderModule = function (objectConfiguration) {

		dis.BaseModule.call(this);

		var defaults = {

			},

			configuration = $.extend(defaults, objectConfiguration),
			dom = {},
			eventHandlers,
			selfScope = this,

			eventHandlers = {
				filterBtnClick: function (e) {
					dis.filters.toggleFilterVisibility();

					e.preventDefault();
				},
			};


		/*
			PRIVATE functions
		*/
	
		function setFilterVisibility() {
			var filters = Cookies.get('filters_str');

			if(filters !== undefined && $(window).width() > 640) {
				dis.filters.toggleFilterVisibility();
			}
		}



		/*
			PUBLIC functions
		*/
	
		this.updateExcelDownloadParameters = function() {
			var filters = Cookies.get('filters_json');

			dom.excelForm.find('.filter-attr').remove();

			if(filters !== undefined) {
				$.each($.parseJSON(filters), function(key, filter) {
					/* jshint unused:vars */
					if(filter.value !== '') {
						dom.excelForm.prepend('<input type="hidden" name="'+ filter.name +'" value="'+ filter.value +'" class="filter-attr" />');
					}
				});
			}

			return selfScope;
		};


		this.SKYCIRCLEAPI_TODO = function() {

			/*
			var currentYear = moment().year();

			$.getJSON("/umbraco/api/cloud/GetAvailable?From="+currentYear+"-01-01&to="+currentYear+"-12-31", function( data ) {
				console.log(data);
			});*/
			
			return selfScope;
		};

		this.updateTotalSailingDays = function(value) {
			dom.totalSailingDays.text(value);
		};
	


		function init() {
			// Get and store the DOM-reference to the container we've been given.
			dom.container = $(configuration.container);
			
			if (!dom.container.length) {
				// If we haven't got a container at this point, throw a hissy fit!
				throw "Given container could not be found, or no container given. That's bad!";
			}
			
			dom.filterBtn 			= dom.container.find('.filter-btn');
			dom.excelForm 			= dom.container.find('#csvForm');
			dom.totalSailingDays 	= dom.container.find('.total-sailing-days');

			/**
			* Handle click on either of the submit buttons
			*/
			dom.filterBtn.on('click', eventHandlers.filterBtnClick);

			setFilterVisibility();

			selfScope.updateExcelDownloadParameters();
		}


		selfScope.onReady(init);

	};

	dis.filterHeaderModule.prototype = new dis.BaseModule();
	dis.filterHeaderModule.constructor = dis.filtersModule;


// This is where you define the global parameters you pass into your closure. You'll want to pass in any
// big stuff you'll need inside your script. Minor stuff can always be accessed through window.someVariable.
}(jQuery, dis));

