/*jshint esnext: true */
/* jshint latedef:nofunc */
/*global jQuery, dis */

/* DIS/PLAY Script
 Author's name: Michael Milo
 Modified by:
 Client name: ...
 Date of creation: ...
 */


(function ($, dis) {
	"use strict";

	 /*
	 * @param objectConfiguration - The configuration for the object
	 * @param {jQuery|HTMLElement|string|*} objectConfiguration.container - The DOM-container for this instantiation
	 * @constructor
	 * @extends dis.BaseModule
	 */
	dis.historyModule = function (objectConfiguration) {

		dis.BaseModule.call(this);

		var defaults = {
				nextPage: 		1,
				totalItems: 	'',
				totalPages: 	'',
				pageSize:     	'',
				historyDT: 		''
			},

			configuration = $.extend(defaults, objectConfiguration),
			dom = {},
			eventHandlers,
			selfScope = this,

			eventHandlers = {
				getHistoryEntries: function (e) {
					selfScope.getHistoryEntries();

					e.preventDefault();
				}
			};


		/*
			PRIVATE functions
		*/

		function initDataTable() {
			configuration.historyDT = dom.historyTable.DataTable({
				bLengthChange: 	false,
				searching: 		false,
				bInfo:   		false,
				bPaginate: 		false,
				responsive: 	true,
				historyDT: 		'',
				order:			[0, "desc"],

				"language": {
					"emptyTable": "Ingen data tilgængelig"
				},

				"fnCreatedRow" 	: function() {
					dom.submitButton.children('totalItems').text( configuration.totalItems - dom.historyTable.find('tbody tr').length );
			    }
			});

			selfScope.getHistoryEntries();
		}

		function appendRows(items) {
			items.map(function(item) {
				configuration.historyDT.row.add( [
		            item.name,
		            item.booker,
		            moment(item.date, 'DD-MM-YYYY').format('DD.MM'),
		            item.size,
		            '<a href="' + item.url + '" target="_blank" rel="noopener"><i class="icon icon-download"></i></a>'
		        ] ).draw( false );
			});
		}

		function setInitialStates(values) {
			if(configuration.nextPage === 1) {
				configuration.totalItems 	= values.totalItems;
				configuration.totalPages 	= values.totalPages;
				configuration.pageSize 		= values.pageSize;

				dom.submitButton.children('pageSize').text(configuration.pageSize);
			}
		}




		/*
			PUBLIC functions
		*/

		this.getHistoryEntries = function () {
			$.ajax({
				method: "POST",
				url: "?page=" + configuration.nextPage,
				dataType: 'json',
				data: dom.form.serialize()
			})

			.done(function( msg ) {
				setInitialStates(msg);
				appendRows(msg.items);

				if(configuration.nextPage >= configuration.totalPages) {
					dom.submitButton.hide();
				}
				else {
					dom.submitButton.show();
				}

				configuration.nextPage = configuration.nextPage + 1;
			})

			.error(function(msg) {
				alert('API\'et returnerede en ukendt fejl. Data kunne ikke hentes.');
				console.log(msg);
			});

			return selfScope;
		};


		function init() {
			// Get and store the DOM-reference to the container we've been given.
			dom.container = $(configuration.container);

			if (!dom.container.length) {
				// If we haven't got a container at this point, throw a hissy fit!
				throw "Given container could not be found, or no container given. That's bad!";
			}

			dom.form 					= dom.container.find('form');
			dom.historyTable 			= dom.container.find('table');
			dom.submitButton			= dom.container.find('[type=submit]');

			initDataTable();

			/**
			* Handle click on either of the submit buttons
			*/
			dom.form.on('submit', eventHandlers.getHistoryEntries);


		}


		selfScope.onReady(init);

	};

	dis.historyModule.prototype = new dis.BaseModule();
	dis.historyModule.constructor = dis.historyModule;


// This is where you define the global parameters you pass into your closure. You'll want to pass in any
// big stuff you'll need inside your script. Minor stuff can always be accessed through window.someVariable.
}(jQuery, dis));
