/*jshint esnext: true */
/* jshint latedef:nofunc */
/*global jQuery, dis */

/* DIS/PLAY Script
 Author's name: Michael Milo
 Modified by:
 Client name: ...
 Date of creation: ...
 */

/*jshint scripturl:true*/

(function ($, dis) {
	"use strict";

	 /*
	 * @param objectConfiguration - The configuration for the object
	 * @param {jQuery|HTMLElement|string|*} objectConfiguration.container - The DOM-container for this instantiation
	 * @constructor
	 * @extends dis.BaseModule
	 */
	dis.bookingOverviewModule = function (objectConfiguration) {

		dis.BaseModule.call(this);

		var defaults = {
				nextPage: 		1,
				onlyNextPage: 	true,
				totalItems: 	'',
				totalPages: 	'',
				pageSize:     	'',
				bookingsDT: 	'' ,
				dateFormat: 	'YYYY-MM-DD',
				deleteApiUrl: 	'/umbraco/surface/FrontPage/delete',
				addedBookingIDs: []
			},

			configuration = $.extend(defaults, objectConfiguration),
			dom = {},
			eventHandlers,
			selfScope = this,
			lastSearchData = null,

			eventHandlers = {
				getBookingsEvent: function (e) {
					selfScope.getBookings();

					e.preventDefault();
				},

				deleteConfirmEvent: function(e) {
					bootbox.confirm({
					    message: 'Er du sikker?',
					    buttons: {
					       	'cancel': {
					            label: 'Fortryd',
					            className: 'btn-default'
					        },
					        'confirm': {
					            label: 'Slet',
					            className: 'btn-danger'
					        }
					    },

					    callback: function(result){
					    	if(result === true) {
						 		deleteBooking(e);
						 	}
					    }
					});

					e.preventDefault();
				},

				columnReorderEvent: function() {
					selfScope.bookingsListSoftReset();
					configuration.nextPage = configuration.nextPage - 1; //Fake the nextPage to stay at current view.

					selfScope.getBookings();

					configuration.onlyNextPage = true;
				},

				showTickets: function (e) {
					$(e.target).toggleClass("active");
				}
			};


		/*
			PRIVATE functions
		*/

		function initDataTable() {
			configuration.bookingsDT = dom.bookingsTable.DataTable({
				bLengthChange: false,
				searching: false,
				bInfo: false,
				bPaginate: false,
				responsive: true,
				columnReorder: false,

				language: {
					emptyTable: "&nbsp;"
				},

				order: [[ 10, "desc" ]],

				columnDefs: [
			        { responsivePriority: 1, targets: 0, },
			        { responsivePriority: 1, targets: 1 },
			        { responsivePriority: 1, targets: 4 },
			        { responsivePriority: 1, targets: 5 },
			        { responsivePriority: 1, targets: -1 },
					{ className: "tickets-col", targets: 6 }
			    ],

			    rowCallback: function( row, data ) {
				    $(row).children('td:first').attr('data-order', data[1]).attr('data-sort', data[1]);
				    dom.submitButton.children('totalItems').text( configuration.totalItems - dom.bookingsTable.find('tbody tr').length );
				}
			});

			//Extend datatables to handle the dateformat we use for sorting.
			//Not needed anyway, I guess. Sorting by ID instead.
			/*
			$.extend( jQuery.fn.dataTableExt.oSort, {
			    "date-da-pre": function ( a ) {
			        if (a === null || a === "") {
			            return 0;
			        }
			        var daDatea = a.split('.');
			        return (daDatea[2] + daDatea[1] + daDatea[0]) * 1;
			    },

			    "date-da-asc": function ( a, b ) {
			        return ((a < b) ? -1 : ((a > b) ? 1 : 0));
			    },

			    "date-da-desc": function ( a, b ) {
			        return ((a < b) ? 1 : ((a > b) ? -1 : 0));
			    }
			} );*/

			selfScope.getBookings();
		}


		function appendBookings(items) {
			var permissionTicketValue,
				permissionTicketName,
				permissionTicketsStr,
				comma;

			items.map(function(item) {
				permissionTicketsStr = '';

				//Skip this item if it already exists in the datatable
				if( $.inArray(item.id, configuration.addedBookingIDs) !== -1 ) {
					return true;
				}

				configuration.addedBookingIDs.push( item.id );

				item.permissionTickets.reverse();

				if( ! $.isEmptyObject(item.permissionTickets) ) {
					permissionTicketsStr += '<div class="tickets">';
					permissionTicketsStr += '<a href="' + (item.permissionTickets.length === 1 ? item.permissionTickets[0].value : 'javascript:;') + '" target="_blank">' + item.permissionTickets[0].name + '</a>';

					if (item.permissionTickets.length > 1) {
						permissionTicketsStr += '<div class="tickets__all">';

						$.each(item.permissionTickets, function( k, v ) {
							if (k === 0) {
								permissionTicketsStr += '<div class="tickets__newest">Hent adg. tegn<br><a href="' + v.value + '" target="_blank">' + v.name + '</a></div>Tidligere versioner<br>';
							} else {
								permissionTicketsStr += '<a href="' + v.value + '" target="_blank">' + v.name + '</a><br>';
							}
						});

						permissionTicketsStr += '</div>';
					}

					permissionTicketsStr += '</div>';

					permissionTicketValue = item.permissionTickets[ item.permissionTickets.length - 1 ].value;
					permissionTicketName = item.permissionTickets[ item.permissionTickets.length - 1 ].name;
				}
				else {
					permissionTicketValue = '';
					permissionTicketName = '';
				}

				if(item.customerName === null || item.customerPhone === null) {
					comma = '';
				}
				else {
					comma = ', ';
				}

				var startDate = item.actualStart || item.startDate;
				var endDate = item.actualEnd || item.endDate;

				var newRow = [
					(item.customerName !== null ? item.customerName : '') + comma + (item.customerPhone !== null ? item.customerPhone : ''),
					item.startStation,
					'<span class="visuallyhidden">' + startDate + "</span>" + moment.parseZone(startDate).format('DD-MM-YY'),
					'<span class="visuallyhidden">' + endDate + "</span>" + moment.parseZone(endDate).format('DD-MM-YY'),
					item.endStation,
					item.amount,
					permissionTicketsStr,
					item.id,
					item.sailingDays,
					'<span class="visuallyhidden">' + item.createDate + "</span>" + moment.parseZone(item.createDate).format('DD-MM-YY'),
					getActionBtns(item.allowEdit, item.isLocked, item.hasEnded, item.id)
				];

				// Show the booker, unless the user is a booker
				if (!configuration.isBooker) {
					newRow.splice(9, 0, item.booker);
				}

				configuration.bookingsDT.row.add(newRow).draw( false );

			});

			 configuration.bookingsDT.responsive.recalc();
		}



		function getActionBtns(allowEdit, isLocked, hasEnded, id) {
			if( ! allowEdit || isLocked ) {
				return '';
			}
			else if( hasEnded ) {
				return '<a href="/sejlads?id=' + id + '"><span class="icon icon-pencil-blue"></span></a>';
			}
			else {
				return '<a href="/sejlads?id=' + id + '"><span class="icon icon-pencil-blue"></span></a> <a href="#" class="delete" data-bookingId="'+ id +'"><span class="icon icon-x-blue"></span></a>';
			}
		}

		function setInitialStates(values) {
			if(configuration.nextPage === 1) {
				configuration.totalItems 	= values.totalItems;
				configuration.totalPages 	= values.totalPages;
				configuration.pageSize 		= values.pageSize;

				dis.filterHeader.updateTotalSailingDays(values.totalSailingDays);

				dom.submitButton.children('pageSize').text(configuration.pageSize);
			}
		}

		function getSortingName() {
			var sortingName;

			$( "table.dataTable thead th" ).each(function() {
				if($(this).hasClass('sorting_asc') || $(this).hasClass('sorting_desc')) {
					sortingName = $(this).data('sortoption');
				}
			});

			return sortingName;
		}

		function getSortingDirection() {
			if(configuration.bookingsDT.order()[0][1] === 'asc') {
				return 'ascending';
			}
			else {
				return 'descending';
			}
		}


		function buildQueryData() {
			var filters 		= Cookies.get('filters_json'),
				otherFilters	= '';

			if(filters !== undefined) {
				filters = $.parseJSON(filters);
			}
			else {
				filters = [];
			}

			filters.push(
				{ name: 'page', value: configuration.nextPage },
			    { name: "OtherFilters", value: otherFilters.slice(0, -1) },
			    { name: "sort", value: getSortingName() },
			    { name: "sortDirection", value: getSortingDirection() },
			    { name: "onlyNextPage", value: configuration.onlyNextPage }
			);


			return filters;
		}

		function deleteBooking(e) {
			var clickedElm = $(e.currentTarget);

			$.ajax({
				method: "POST",
				url: configuration.deleteApiUrl + '?bookingId=' + clickedElm.data('bookingid'),
			})

			.done(function() {
				var row = clickedElm.parents('tr');

				if (row.hasClass("child")) {
					row = row.prev();
				}

				configuration.bookingsDT.row( row ).remove().draw();
			})

			.error(function(jqXHR, status, thrownError) {
				/* jshint unused:vars */

				alert('Der skete en fejl:' + thrownError);
			});
		}






		/*
			PUBLIC functions
		*/

		/**
		* Perform the tryReserve API request. Accepts the parameters below:
		* 	 int 		BookerId
	    *    int 		StartStation
	    *    int 		EndStation
	    *    string 	SearchQuery
	    *    DateTime 	StartPeriodMin
	    *    DateTime 	StartPeriodMax
	    *    DateTime 	EndPeriodMin
	    *    DateTime 	EndPeriodMax
	    *    DateTime 	StartCreatedTime
	    *    DateTime 	EndCreatedTime
	    *    Constants 	OtherFilters
		* @return {self|boolean}
		*/
		this.getBookings = function (resetBookingList) {
			var serializedData = dom.form.serializeArray(),
				queryFilterData = buildQueryData();

			serializedData = $.merge(serializedData, queryFilterData);

			if (JSON.stringify(serializedData) === JSON.stringify(lastSearchData)) {
				return false;
			} else if (resetBookingList === true) {
				this.bookingsListReset();

				serializedData = dom.form.serializeArray();
				queryFilterData = buildQueryData();
				serializedData = $.merge(serializedData, queryFilterData);
			}

			lastSearchData = serializedData;

			dom.loader.show();
			dom.submitButton.hide();

			$.ajax({
				method: "POST",
				url: "/",
				dataType: 'json',
				data: serializedData
			})

			.done(function( msg ) {

				dom.loader.hide();
				dom.submitButton.show();

				setInitialStates(msg);

				appendBookings(msg.items);

				if(configuration.nextPage >= configuration.totalPages) {
					dom.submitButton.hide();
				}
				else {
					dom.submitButton.show();
				}

				configuration.nextPage = configuration.nextPage + 1;
			})

			.error(function(msg) {
				alert('API\'et returnerede en ukendt fejl. Data kunne ikke hentes.');
				console.log(msg);
			});

			return selfScope;
		};

		this.bookingsListReset = function() {
			configuration.bookingsDT.clear().draw();
			configuration.nextPage = 1;
			configuration.onlyNextPage = 0;
			configuration.addedBookingIDs = [];

			return selfScope;
		};

		this.bookingsListSoftReset = function() {
			configuration.bookingsDT.clear().draw();
			configuration.onlyNextPage = false;
			configuration.addedBookingIDs = [];

			return selfScope;
		};


		function init() {
			// Get and store the DOM-reference to the container we've been given.
			dom.container = $(configuration.container);

			if (!dom.container.length) {
				// If we haven't got a container at this point, throw a hissy fit!
				throw "Given container could not be found, or no container given. That's bad!";
			}

			dom.form = dom.container.find('form');
			dom.bookingsTable = dom.container.find('table');
			dom.submitButton = dom.container.find('[type=submit]');
			dom.modalStartDate = dom.container.find('.modal input[name="StartDate"]');
			dom.loader = dom.container.find(".loader");

			configuration.isBooker = dom.bookingsTable.hasClass("isBooker");

			initDataTable();

			/**
			* Handle click on either of the submit buttons
			*/
			dom.form.on('submit', eventHandlers.getBookingsEvent);


			dom.bookingsTable.on('click', '.delete', eventHandlers.deleteConfirmEvent);

			dom.bookingsTable.on('column-reorder', '.delete', eventHandlers.deleteConfirmEvent);

			dom.bookingsTable.on('click tap', 'th', eventHandlers.columnReorderEvent);

			dom.bookingsTable.on('click', '.tickets > a', eventHandlers.showTickets);

		}


		selfScope.onReady(init);

	};

	dis.bookingOverviewModule.prototype = new dis.BaseModule();
	dis.bookingOverviewModule.constructor = dis.bookingOverviewModule;


// This is where you define the global parameters you pass into your closure. You'll want to pass in any
// big stuff you'll need inside your script. Minor stuff can always be accessed through window.someVariable.
}(jQuery, dis));

